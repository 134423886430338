import { useRef, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import useQueryOne from "../helpers/useQueryOne";
import { Form, ViewButton, Button } from "../styles/styles";

import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { Header } from "./Header";
import { Cards } from "./Cards/Cards";
import { TableView } from "./TableView";

const useViewSelector = () => {
  const [isCardView, setIsCardView] = useState(false)
  const handler = useMemo(()=> ([
    isCardView,
    ()=> <ViewButton onClick={() => setIsCardView(!isCardView)}>Switch to {isCardView ? 'list view' : 'card view'}</ViewButton>,
  ]), [isCardView])
  return handler
}

const Search = () => {
  const inputRef = useRef();
  const [query, setQuery] = useState(null)
  const { data, isError, isLoading } = useQueryOne(
    query
  );
  const [isCardView, ViewSelector] = useViewSelector()
  const [params] = useSearchParams();
  const navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    const q = params.get('search')
    if (q) {
      setQuery(q)

    }
  }, [params])

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    const q = formData.get('query')
    navigate(location.pathname + `?search=${q}`)
  }

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <section>
      <SearchContainer>
        <Header/>
        <Form onSubmit={handleSubmit} flexDirection="row">
          <input
            ref={inputRef}
            type="text"
            name="query"
            placeholder="enter your query here"
          />
          <Button type="submit" isPrimaryColor={false}>Search</Button>
        </Form>
        {data && <ViewSelector/>}
      </SearchContainer>

      {isLoading && (
        <StyledLoading>
          Loading definitions
        </StyledLoading>
      )}
      {isError && (
        <StyledError>
          Sorry, there was an error. Please try again later!
        </StyledError>
      )}
      {isCardView ? <Cards data={data} /> : <TableView data={data} />}
    </section>
  );
};

export default Search;

const StyledLoading = styled.p`
  color: whitesmoke;
  opacity: 0.5;
  text-align: center;
`

const StyledError = styled.p`
  color: red;
  text-align: center;
`

const SearchContainer = styled.div`
  margin: 0 auto;
  margin-top: 30vh;
  margin-bottom: 50px;
  width: 100vw;
  display: flex;
  flex-direction: column;
`;