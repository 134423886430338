import { Table } from "../styles/styles";

export const TableView = ({ data }) => {
  if (!data) {
    return null
  }
  return (
    <Table>
      <thead style={{ marginBottom: 20 }}>
        <tr>
          <th>result</th>
          <th>term</th>
          <th>translation</th>
          <th>definition</th>
        </tr>
      </thead>

      <tbody>
        {data &&
          data.map((element, idx) => {
            return (
              <tr key={idx} className="ResultsContainerTR">
                <td>{idx + 1}</td>
                <td>{element.title}</td>
                <td>{element.snippet}</td>
                <td className="ResultsContainerTD">{element.definition}</td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};