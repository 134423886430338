export const mode = process.env.NODE_ENV;
export const isDev = process.env.NODE_ENV === "development";
export const isProd = process.env.NODE_ENV === "production";

export const isDevFeature = (featureName) => {
    return featureList[featureName][mode]
}

const featureList = {
    "card_modal": {
        development: true,
        production: false
    }
}