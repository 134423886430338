import { useCommentModal } from "..//useCommentModal";
import { nullFunc } from "../utils/nullFunc";
import { isDevFeature } from "../../libs/featureManager";
import styled from "styled-components";

export const Cards = ({ data }) => {
  const [modalController, CommentModal] = useCommentModal()

  if (!data) {
    return null
  }
  return (
    <CardsContainer>
      <CommentModal/>
      {data.map((element, idx) => {
        return (
            <Card key={idx} onClick={isDevFeature('card_modal') ? modalController.open : nullFunc}>
              <p className="title">{element.title}</p>
              <p className="snippet">{element.snippet}</p>
              <p>{element.definition}</p>
            </Card>
        );
    })}
    </CardsContainer>
  )
}

const CardsContainer = styled.div`
  width: clamp(280px, 80%, 1200px);
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin: 0 auto;
`

const Card = styled.div`
height: auto;
padding: 20px;
width: 49%;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
border-radius: 10px;
transition: transform 250ms ease-in;
background-color: white;
cursor: pointer;
:hover {
  box-shadow: inset 0 0 0 4px var(--primaryColor);
}
.title {
  font-weight: 700;
}
.snippet {

}

`;