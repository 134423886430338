import styled from "styled-components";

export const Header = () => (
    <StyledHeader>
        <h1>
            Tango Definitions
            <Underline>.com</Underline>
        </h1>
        <h3>by Matias Facio</h3>
    </StyledHeader>
)

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 10px;
  h1 {
    font-size: 2.8rem;
    margin-bottom: 20px;
  }
`;

const Underline = styled.span`
  border-bottom: 5px var(--primaryColor) solid;
`