import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createGlobalStyle } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import AdminContextProvider from "./contexts/AdminContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { createRoot } from "react-dom/client";

const GlobalStyle = createGlobalStyle`
  :root {
    // --dark: #292929;
    // --bg-dark: #F1F4F9;
    // --light: #ffffff;
    // --redish: rgb(210, 105, 30);
    // --black: #171515;
    // --primaryColor: #828CFF;
    --dark: #292929;
    --bg-dark: #292929;
    --light: #ffffff;
    --redish: rgb(210, 105, 30);
    --black: #ffffff;
    --primaryColor: #828CFF;
    --text-color: #ffffff;
    --text-color-dark: #292929;
    --border-radius: 10px;
  }

  * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  }

  body {
    width: 100vw;
    overflow-x: hidden;
    background-color: var(--bg-dark);
    padding: 0px 2em;
    color: var(--text-color);
  }



h1 {
  font-family: "Chunkfive";
  font-size: clamp(1.5rem, 5vw, 4rem);
  color: var(--text-color);
}

h3 {
  color: var(--text-color);
  font-size: clamp(0.8rem, 2vw, 1.7rem);
}

p {
  line-height: 1.6;
  color: var(--text-color-dark)
}

a {
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;
  &:hover {
    color: var(--redish);
  }
}
`;

const queryClient = new QueryClient();

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <QueryClientProvider client={queryClient}>
    <GlobalStyle />
    <BrowserRouter>
      <AdminContextProvider>
        <App />
      </AdminContextProvider>
    </BrowserRouter>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
