import { useQuery } from "react-query";
import { baseURL } from "./baseUrl";

function useQueryOneById(id) {
  return useQuery(
    !!id && ["definition", id],
    async () =>
      await fetch(`${baseURL}adminarea/searchbyid/${id}`).then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("error fetching definition");
        }
      }),
    {
      refetchOnWindowFocus: false
    }
  );
}

export default useQueryOneById;
