import { useMemo, useState } from "react"
import styled from "styled-components"
import { Button } from "../styles/styles"

export const useCommentModal = () => {
    const [isOpen, setIsOpen] = useState(false)
    const openCommentModal = () =>setIsOpen(true)
    const closeCommentModal = () => setIsOpen(false)

    const modalController = useMemo(() => ({
        open: openCommentModal,
        close: closeCommentModal
    }), [])
    return [modalController, ()=> <CommentModal isOpen={isOpen} onClose={closeCommentModal}/>]
}

const CommentModal = ({ isOpen, onClose}) => {
    return (
        <StyledModalBackdrop isOpen={isOpen}>
            <StyledCommentModal>
                <StyledCloseButtonContainer>
                    <Button onClick={onClose}>X</Button>
                </StyledCloseButtonContainer>
                Content Modal
            </StyledCommentModal>
        </StyledModalBackdrop>
    )
}

const StyledModalBackdrop = styled.div`
    inset: 0;
    position: fixed;
    background-color: transparent;
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
     ${({isOpen}) => isOpen ? "display: block" : "display: none"}
`

const StyledCommentModal = styled.div`
    background-color: white;
    width: 70%;
    height: 70%;
    margin-inline: auto;
    margin-block: 100px;
    border-radius: var(--border-radius);
    color: var(--text-color-dark);
    box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
    position: relative;
    box-shadow: 0 0 0 4px var(--primaryColor);
`

const StyledCloseButtonContainer = styled.div`
    position: absolute;
    right: -10px;
    top: -10px;;
`