import styled from "styled-components";

export const Button = styled.button`
  padding: 7px 20px;
  border-radius: var(--border-radius);
  background-color: var(--primaryColor);
  color: white;
  outline: none;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  max-width: 15ch;
  cursor: pointer;
`;

export const ViewButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 1rem;
  color: var(--text-color);
  text-decoration: underline;
  text-decoration-color: var(--primaryColor);
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
  cursor: pointer;
  font-weight: 600;
`

export const UnderlineButton = styled.p`
  border-bottom: 2px var(--primaryColor) solid;
  color: var(--text-color);
  text-transform: uppercase;
  font-size: 1rem;
`

export const Hr = styled.hr`
  color: green;
  border: none;
  height: 3px;
  background-color: #828CFF;
`;

export const Botonera = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`;

export const Table = styled.table`
  width: clamp(280px, 80%, 1200px);
  padding: 0vw;
  margin: 50px auto;
  color: var(--text-color);
  thead {
    margin-bottom: 20px;
    tr {
      border-radius: 2px;
      th {
        padding: 5px 0;
      }
    }
  }
  tbody {
    background-color: white;
    color: var(--text-color-dark);
    .ResultsContainerTR {
      margin: 20px;
    }

    tr {
      border-radius: 2px;
    }

    td {
      padding: 10px;
      border-radius: 2px;
    }

    .ResultsContainerTD {
      max-width: 30vw;
      white-space: pre-wrap;
    }
  }
`;

export const Form = styled.form`
  margin: 20px auto;
  display: flex;
  flex-direction: ${({flexDirection})=> flexDirection === 'column' ? 'column':'row'};
  align-items: center;
  input {
    width: 90vw;
    font-size: 1.3rem;
    padding: 6px 15px;
    margin: 10px 0px;
    border-radius: var(--border-radius);
    border: none;
    outline: none;
    color:var(--text-color-dark);
    &::placeholder {
      font-size: 1rem;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  textarea {
    height: clamp(400px, 50vh, 600px);
    overflow-y: scroll;
    border-radius: 2px;
    border: none;
    margin: 10px 0px;
    font-size: 1.3rem;
    padding: 6px 15px;
    outline: none;
  }
  @media screen and (min-width: 600px) {
    flex-direction: ${({flexDirection})=> flexDirection === 'column' ? 'column':'row'};;
    input {
      width: 50vw;
    }
  }
`;
