import { useQuery } from "react-query";
import { baseURL } from "./baseUrl";

function useQueryAll() {
  return useQuery(
    "definitions",
    () =>
      fetch(`${baseURL}search/searchall`).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error fetching data");
        }
      }),
    {
      staleTime: 5000,
      refetchOnWindowFocus: false
    }
  );
}

export default useQueryAll;
